import moment from "moment";

import { isPastDue, javaDateToDate } from "Utils";
import {
    onlineOrderStatus,
    KitchenOrderStatus,
    declinedOnlineOrderStatuses,
    failedPaymentOnlineOrderStatuses
} from "Constants";
import { formatDateToLocal } from "TempUtils";
import { ILanguages, Languagekey, dictionaryList } from "LanguageProvider"
import { EatingOption, Order, StatusTimestamp } from "Types";

const homeDeliverySteps = [
    { titleKey: "payed", status: onlineOrderStatus.WAITING },
    { titleKey: "confirmed", status: onlineOrderStatus.PROCESSING },
    { titleKey: "theFoodIsOnItsWay", status: onlineOrderStatus.READY_FOR_PICKUP }
];

const tableServiceSteps = [
    { titleKey: "payed", status: onlineOrderStatus.WAITING },
    { titleKey: "confirmed", status: onlineOrderStatus.PROCESSING },
    { titleKey: "served", status: onlineOrderStatus.READY_FOR_PICKUP }
];

const lastMileDeliverySteps = homeDeliverySteps.concat({ titleKey: "delivered", status: onlineOrderStatus.DELIVERED });
const deliverySteps = [
    { titleKey: "payed", status: onlineOrderStatus.WAITING },
    { titleKey: "confirmed", status: onlineOrderStatus.PROCESSING },
    { titleKey: "readyForPickup", status: onlineOrderStatus.READY_FOR_PICKUP },
    { titleKey: "pickedUp", status: onlineOrderStatus.DELIVERED }
];

const getFormattedPickupTime = (pickupTime: string | Date, userLanguage: ILanguages): string => {
    const now = moment().locale(userLanguage);
    const tillPickup = now.to(javaDateToDate(pickupTime));
    return tillPickup.substr(tillPickup.indexOf(" ") + 1);
};

const getPickupLabel = (
    pickupTime: string | Date,
    kitchenOrderStatus: string,
    homeDelivery: boolean,
    delivered: boolean = false,
    hasTableMeta: boolean = false,
    userLanguage: ILanguages,
    isDisableTableService?: boolean
): string => {
    const langToUse = dictionaryList[userLanguage];

    if ([onlineOrderStatus.DENIED, onlineOrderStatus.ERROR_DELIVERY_CREATE].includes(kitchenOrderStatus)) {
        return langToUse["failed"];
    }

    if (delivered) {
        return langToUse["successfulDelivery"];
    }

    if (kitchenOrderStatus === onlineOrderStatus.DELIVERED) {
        if (homeDelivery) {
            return langToUse["theFoodIsOnItsWay"];
        } else if (hasTableMeta && !isDisableTableService) {
            return langToUse["served"];
        }
        return langToUse["pickedUp"];
    }

    if (homeDelivery) {
        return `${langToUse["approxDeliveryTime"]} ${getFormattedPickupTime(pickupTime, userLanguage)}`;
    }

    if (hasTableMeta && !isDisableTableService && kitchenOrderStatus === onlineOrderStatus.READY_FOR_PICKUP)
        return langToUse["soonToBeServed"];
    if (kitchenOrderStatus === onlineOrderStatus.READY_FOR_PICKUP) return langToUse["readyForPickup"];

    const pastDue = isPastDue(pickupTime);
    if (pastDue && kitchenOrderStatus !== onlineOrderStatus.READY_FOR_PICKUP) return langToUse["delayed"];
    return `${langToUse["approx"]} ${getFormattedPickupTime(pickupTime, userLanguage)}`;
};

const formatDeniedOrderMsg = (msg: string): Languagekey => {
    let formattedMsgKey: Languagekey;
    if (!msg) {
        formattedMsgKey = "orderNotFound";
    } else if (msg === onlineOrderStatus.DENIED) {
        formattedMsgKey = "deniedOrder";
    } else if (msg === onlineOrderStatus.CANCELLED_PAYMENT) {
        formattedMsgKey = "errorCancelledPayment";
    } else if (msg === onlineOrderStatus.DECLINED || msg == onlineOrderStatus.FAILED_PAYMENT) {
        formattedMsgKey = "failedPayment";
    } else if (msg === onlineOrderStatus.ERROR_DELIVERY_CREATE) {
        formattedMsgKey = "errorDeliveryCreate";
    } else if (msg === onlineOrderStatus.CANCELLED) {
        formattedMsgKey = "errorCancelledPayment";
    } else if (msg === onlineOrderStatus.OUT_OF_STOCK) {
        formattedMsgKey = "errorOutOfStock";
    } else {
        formattedMsgKey = "generalOrderErrorMsg";
    }

    return formattedMsgKey;
};

const getOrderStepTimestamp = (
    orderStepStatus: string,
    orderStatusTimestamps: StatusTimestamp[],
    onlineOrderStatusTimestamps: StatusTimestamp[]
) => {
    if (orderStepStatus === onlineOrderStatus.PROCESSING) {
        const confirmedOnlineOrderStatus = onlineOrderStatusTimestamps.find(
            ({ onlineOrderStatus }) => onlineOrderStatus === "CONFIRMED"
        );

        return confirmedOnlineOrderStatus ? formatDateToLocal(confirmedOnlineOrderStatus.timestamp) : "";
    } else {
        const foundTimeStamp = orderStatusTimestamps.find(({ status }) => status === orderStepStatus);
        return foundTimeStamp ? formatDateToLocal(foundTimeStamp.timestamp) : "";
    }
};

const pickupTimeText = (
    order: Order,
    isDisableTableService: boolean,
    latestStatus: string,
    userLanguage: ILanguages
) => {
    const langToUse = dictionaryList[userLanguage];
    let textKey;
    const isHomeDelivery = order.eatingOption === EatingOption.HOME_DELIVERY;

    if (order.deliveryCreateResponse && order.deliveryCreateResponse.arriveAtCustomerTime) {
        textKey = getPickupLabel(
            order.deliveryCreateResponse.arriveAtCustomerTime,
            latestStatus,
            isHomeDelivery,
            !!order.deliveryCreateResponse.delivered,
            false,
            userLanguage
        );
    } else if (order.onlineOrderStatus === onlineOrderStatus.CANCELLED) {
        textKey = langToUse["cancelled"];
    } else if (
        [...declinedOnlineOrderStatuses, ...failedPaymentOnlineOrderStatuses].includes(order.onlineOrderStatus)
    ) {
        textKey = langToUse["failed"];
    } else if (order.onlineOrderStatus === onlineOrderStatus.PENDING && latestStatus === KitchenOrderStatus.WAITING)
        textKey = langToUse["awaitingConfirmation"];
    else if (order.autoAccepted && !isDisableTableService && !!order.tableMeta) {
        textKey = langToUse["soonToBeServed"];
    } else if (order.pickupTime) {
        textKey = getPickupLabel(
            order.pickupTime,
            latestStatus,
            isHomeDelivery,
            false,
            !!order.tableMeta,
            userLanguage,
            isDisableTableService
        );
    } else if ([onlineOrderStatus.PENDING, onlineOrderStatus.PROCESSING].includes(latestStatus)) {
        textKey = langToUse["processingOrder"];
    } else textKey = langToUse["failed"];
    return textKey;
};

export {
    formatDeniedOrderMsg,
    getOrderStepTimestamp,
    homeDeliverySteps,
    lastMileDeliverySteps,
    deliverySteps,
    pickupTimeText,
    getPickupLabel,
    tableServiceSteps
};
